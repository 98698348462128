import React from 'react'

import Layout from '../components/layout/layout'
import SubHero from '../components/sub-hero/sub-hero'
import PrivacyPolicy from '../components/privacy-policy/privacy-policy'

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Layout title="Contact Us">
        <SubHero
          title="Privacy Policy"
          subtitle="This Privacy Policy describes our policies and procedures on the collection,
          use and disclosure of your information when using this website."
        />
        <div style={{ height: '5rem' }} />
        <PrivacyPolicy />
      </Layout>
    </div>
  )
}
 
export default PrivacyPolicyPage;