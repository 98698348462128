import React from 'react'
import { Link } from 'gatsby'

import Container from '../container/container'

import styles from './privacy-policy.module.scss'

const PrivacyPolicy = () => {
  return (
    <Container>
      <div className={styles.privacyPolicy}>
        <span className={styles.lastUpdated}>Last Updated: 23 December 2020</span>
        <h3>INTRODUCTION</h3>
        <p>
          Max2 Security LLC. (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”).
          This Privacy Policy explains how we collect, use, disclose, and safeguard your information when 
          you visit our website max2security.com, including any other media form, media channel, mobile 
          website, or mobile application related or connected thereto (collectively, the “Site”). Please 
          read this privacy policy carefully.  If you do not agree with the terms of this privacy policy, 
          please do not access the site.
        </p>
        <p>
          We reserve the right to make changes to this Privacy Policy at any time and for any reason.  
          We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy.  
          Any changes or modifications will be effective immediately upon posting the updated Privacy Policy 
          on the Site, and you waive the right to receive specific notice of each such change or modification.
        </p>
        <p>
          You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will 
          be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the 
          changes in any revised Privacy Policy by your continued use of the Site after the date such revised 
          Privacy Policy is posted. 
        </p>

        <h3>COLLECTION OF YOUR INFORMATION</h3>
        <p>
          We may collect information about you in a variety of ways. The information we may collect on the Site 
          includes:
        </p>
        <h5>Personal Data </h5>
        <p>  
          Personally identifiable information, such as your name, email address, and telephone number when you 
          choose to participate in various activities related to the Site, such as using a contact form. You 
          are under no obligation to provide us with personal information of any kind, however your refusal to do 
          so may prevent you from using certain features of the Site.
        </p>
        <h5>Derivative Data</h5>
        <p>  
          Information our servers automatically collect when you access the Site, such as your IP address, your 
          browser type, your operating system, your access times, and the pages you have viewed directly before 
          and after accessing the Site.
        </p>
        <h5>Mobile Device Data </h5>
        <p>  
          Device information, such as your mobile device ID, model, and manufacturer, and information about the 
          location of your device, if you access the Site from a mobile device.
        </p>
        <h5>Data From Contests, Giveaways, and Surveys</h5>
        <p>  
          Personal and other information you may provide when entering contests or giveaways and/or responding to 
          surveys.
        </p>
        <h5>Mobile Application Information</h5>
        <p>  
          If you connect using our mobile application:
        </p>
        <ul>
          <li>
            Geo-Location Information. We may request access or permission to and track location-based information 
            from your mobile device, either continuously or while you are using our mobile application, to provide 
            location-based services. If you wish to change our access or permissions, you may do so in your device’s 
            settings.
          </li>
          <li>
            Mobile Device Data. We may collect device information (such as your mobile device ID, model and 
            manufacturer), operating system, version information and IP address.
          </li>
        </ul>

        <h3>USE OF YOUR INFORMATION</h3>
        <p>  
          Having accurate information about you permits us to provide you with a smooth, efficient, and customized 
          experience.  Specifically, we may use information collected about you via the Site to: 
        </p>
        <ul>
          <li>Assist law enforcement and respond to subpoena.</li>
          <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
          <li>Create and manage your account.</li>
          <li>Deliver targeted advertising, newsletters, and other information regarding promotions and the Site to you.</li>
          <li>Generate a personal profile about you to make future visits to the Site more personalized.</li>
          <li>Increase the efficiency and operation of the Site.</li>
          <li>Monitor and analyze usage and trends to improve your experience with the Site.</li>
          <li>Notify you of updates to the Site.</li>
          <li>Offer new products, services, and/or recommendations to you.</li>
          <li>Perform other business activities as needed.</li>
          <li>Request feedback and contact you about your use of the Site.</li>
          <li>Resolve disputes and troubleshoot problems.</li>
          <li>Respond to product and customer service requests.</li>
          <li>Send you a newsletter.</li>
          <li>Solicit support for the Site.</li>
        </ul>

        <h3>DISCLOSURE OF YOUR INFORMATION</h3>
        <p>  
          We may share information we have collected about you in certain situations. Your information may be 
          disclosed as follows: 
        </p>
        <h5>By Law or to Protect Rights</h5>
        <p>  
          If we believe the release of information about you is necessary to respond to legal process, to 
          investigate or remedy potential violations of our policies, or to protect the rights, property, 
          and safety of others, we may share your information as permitted or required by any applicable law, 
          rule, or regulation.  This includes exchanging information with other entities for fraud protection 
          and credit risk reduction.
        </p>
        <h5>Third-Party Service Providers </h5>
        <p>  
          We may share your information with third parties that perform services for us or on our behalf, 
          including data analysis, email delivery, hosting services, customer service, 
          and marketing assistance.
        </p>
        <h5>Marketing Communications</h5>
        <p>  
          With your consent, or with an opportunity for you to withdraw consent, we may share your information 
          with third parties for marketing purposes, as permitted by law.
        </p>
        <h5>Interactions with Other Users</h5>
        <p>  
          If you interact with other users of the Site, those users may see your name, profile photo, and 
          descriptions of your activity, including sending invitations to other users, chatting with other 
          users, liking posts, following blogs. 
        </p>
        <h5>Online Postings</h5>
        <p>  
          When you post comments, contributions or other content to the Site , your posts may be viewed by all 
          users and may be publicly distributed outside the Site in perpetuity. 
        </p>
        <h5>Third-Party Advertisers</h5>
        <p>  
          We may use third-party advertising companies to serve ads when you visit the Site. These companies may 
          use information about your visits to the Site and other websites that are contained in web cookies in 
          order to provide advertisements about goods and services of interest to you. 
        </p>
        <h5>Affiliates</h5>
        <p>  
          We may share your information with our affiliates, in which case we will require those affiliates to 
          honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture 
          partners or other companies that we control or that are under common control with us.
        </p>
        <h5>Business Partners</h5>
        <p>  
          We may share your information with our business partners to offer you certain products, services or 
          promotions. 
        </p>
        <h5>Other Third Parties</h5>
        <p>  
          We may share your information with advertisers and investors for the purpose of conducting general 
          business analysis. We may also share your information with such third parties for marketing purposes, 
          as permitted by law. 
        </p>
        <h5>Sale or Bankruptcy</h5>
        <p>  
          If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another 
          entity, we may transfer your information to the successor entity.  If we go out of business or enter 
          bankruptcy, your information would be an asset transferred or acquired by a third party.  You acknowledge 
          that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy 
          Policy.
        </p>
        <p>  
          We are not responsible for the actions of third parties with whom you share personal or sensitive data, and 
          we have no authority to manage or control third-party solicitations.  If you no longer wish to receive 
          correspondence, emails or other communications from third parties, you are responsible for contacting the 
          third party directly.
        </p>

        <h3>TRACKING TECHNOLOGIES</h3>
          
        <h5>Cookies and Web Beacons</h5>
        <p>  
          We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize 
          the Site and improve your experience. When you access the Site, your personal information is not collected 
          through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or 
          reject cookies, but be aware that such action could affect the availability and functionality of the Site. You 
          may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying 
          your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline 
          cookies on an individual basis.
        </p>
        <p>  
          We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize 
          the Site and improve your experience. For more information on how we use cookies, please refer to our Cookie 
          Policy posted on the Site, which is incorporated into this Privacy Policy. By using the Site, you agree to be 
          bound by our Cookie Policy.
        </p>
        
        <h3>YOUR INFORMATION</h3>
        <h5>Internet-Based Advertising</h5>
        <p>  
          Additionally, we may use third-party software to serve ads on the Site, implement email marketing campaigns, 
          and manage other interactive marketing initiatives.  This third-party software may use cookies or similar 
          tracking technology to help manage and optimize your online experience with us.  For more information about 
          opting-out of interest-based ads, visit the Network Advertising Initiative Opt-Out Tool or Digital Advertising 
          Alliance Opt-Out Tool.
        </p>
        <h5>Website Analytics</h5>
        <p>  
          You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or 
          erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or 
          settings.
        </p>
        
        <h3>THIRD-PARTY WEBSITES</h3>
        <p>  
          The Site may contain links to third-party websites and applications of interest, including advertisements and 
          external services, that are not affiliated with us. Once you have used these links to leave the Site, any 
          information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee 
          the safety and privacy of your information. Before visiting and providing any information to any third-party 
          websites, you should inform yourself of the privacy policies and practices (if any) of the third party 
          responsible for that website, and should take those steps necessary to, in your discretion, protect the 
          privacy of your information. We are not responsible for the content or privacy and security practices and 
          policies of any third parties, including other sites, services or applications that may be linked to or 
          from the Site.
        </p>

        <h3>SECURITY OF YOUR INFORMATION</h3>
        <p>  
          We use administrative, technical, and physical security measures to help protect your personal information.  
          While we have taken reasonable steps to secure the personal information you provide to us, please be aware 
          that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission 
          can be guaranteed against any interception or other type of misuse.  Any information disclosed online is 
          vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete 
          security if you provide personal information.
        </p>

        <h3>POLICY FOR CHILDREN</h3>
        <p>  
          We do not knowingly solicit information from or market to children under the age of 13. If you become aware 
          of any data we have collected from children under age 13, please contact us using the contact information 
          provided below. 
        </p>

        <h3>OPTIONS REGARDING YOUR INFORMATION</h3>
        <h5>Emails and Communications</h5>
        <p>  
          If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:
        </p>
        <ul>
          <li>Noting your preferences at the time you register your account with the Site.</li>
          <li>Logging into your account settings and updating your preferences.</li>
          <li>Contacting us using the contact information provided below.</li>
        </ul>
        <p>
          If you no longer wish to receive correspondence, emails, or other communications from third parties, you are 
          responsible for contacting the third party directly. 
        </p>

        <h3>CALIFORNIA PRIVACY RIGHTS</h3>
        <p>  
          California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are 
          California residents to request and obtain from us, once a year and free of charge, information about categories 
          of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and 
          addresses of all third parties with which we shared personal information in the immediately preceding calendar 
          year. If you are a California resident and would like to make such a request, please submit your request in 
          writing to us using the contact information provided below.
        </p>
        <p>  
          If you are under 18 years of age, reside in California, and have a registered account with the Site], you have 
          the right to request removal of unwanted data that you publicly post on the Site. To request removal of such 
          data, please contact us using the contact information provided below, and include the email address associated 
          with your account and a statement that you reside in California.  We will make sure the data is not publicly 
          displayed on the Site, but please be aware that the data may not be completely or comprehensively removed from 
          our systems.
        </p>

        <h3>CONTACT US</h3>
        <p>  
          If you have questions or comments about this Privacy Policy, please <Link to="/contact">contact us</Link>.
        </p>
      </div>
    </Container>
  )
}
 
export default PrivacyPolicy